<template>
  <div class="flexile_wrap">
    <h2 class="flexa">灵活就业</h2>
    <div class="record">
      <el-table
          :data="list"
          stripe
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="applyTime" label="报名时间"></el-table-column>
        <el-table-column label="审核状态">
          <template slot-scope="scope">
            <p v-if="scope.row.auditStatus == 0" class="p3">待审核</p>
            <p v-if="scope.row.auditStatus==1" class="p1">审核通过</p>
             <p v-if="scope.row.auditStatus==2" class="p2"><el-tooltip class="item" effect="dark" :content="scope.row.auditMsg" placement="top"><el-badge value="?" class="item">审核不通过</el-badge></el-tooltip></p>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="$linkRouter('/public/employment/detail',{id:scope.row.id})"
            ><p class="p4">查看详情</p></el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pages">
        <el-pagination
            background
            class="work_pagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            :page-size="page.pageSize"
            layout="prev, pager, next, total, jumper"
            :total="page.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list:[],
      page:{
        pageNum:1,
        pageSize:10,
        total:0
      },
      tableData: [
        {
          date: "阿里云客服",
          name: "2020年11月12日 16:30",
          status: 1
        },
        {
          date: "阿里云客服",
          name: "2020年11月12日 16:30",
          status: 2
        },
        {
          date: "阿里云客服",
          name: "2020年11月12日 16:30",
          status: 3
        },
      ],
    };
  },
  methods: {
   async info(){
      let res = await this.$axios.post('/api/app-jycy-henan/henan/lhjy/apply/list',this.page);
      if (res.data.success){
        this.list = res.data.data.records;
      }
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.info();
      window.scrollTo(0, 0);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    this.info();
  }
};
</script>

<style lang="less" scoped>
@import "~assets/css/henan/flexbile";
</style>
